<template>
  <div class="ServiceInfoForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>

        <col2-block title="基础信息">
          <el-form-item label="所属项目">
            <span v-text="communityName"></span>
          </el-form-item>
          <el-form-item label="应用名称">
            <span v-text="form.name"></span>
          </el-form-item>
          <el-form-item v-if="childTypeVisible" label="应急分类" :rules="[{ type: 'array', required: true, message: '请选择应急分类', trigger: 'blur' }]" prop="childType">
            <checkbox-plus type="default" mode="checkbox-plus" :options="childTypeOps" :value.sync="form.childType" />
          </el-form-item>
          <el-form-item label="服务电话" :rules="[{ required: true, message: '请输入电话号码', trigger: 'change' },{ validator: validatePhone, trigger: 'blur' }]" prop="phone">
            <v-input v-model="form.phone" placeholder="请输入电话号码" :width="width" />
          </el-form-item>
          <!-- <el-form-item label="应用描述" :rules="[{ required: true, message: '请输入应用描述', trigger: 'change' }]" prop="description">
            <v-input v-model="form.description" placeholder="请输入应用描述" :width="width" />
            <span class="tips">注：请简单描述应用内容</span>
          </el-form-item> -->
          <el-form-item label="应用状态" :rules="[{ required: true, message: '请选择应用状态', trigger: 'change' }]" prop="status">
            <v-select v-model="form.status" :options="statusOps" :width="width" />
          </el-form-item>
        </col2-block>

        <col2-block title="权限信息">
          <el-form-item label="可用应用条件" :rules="[{ required: true, message: '请选择可用应用条件', trigger: 'change' }]" prop="conditionsOfUse">
            <checkbox-plus mode="cascade" :options="conditionsOfUseOps" :value.sync="form.conditionsOfUse" @onChange="conditionsOfUseChange" />
          </el-form-item>
          <el-form-item label="是否需要实名" :rules="[{ required: true, message: '请选择是否需要实名', trigger: 'change' }]" prop="realName">
            <v-select v-model="form.realName" :options="realNameOps" :width="width" @change="realNameChange" />
          </el-form-item>
          <el-form-item v-if="orgTagVisible" label="关联组织标签" :rules="[{ type: 'array', required: true, message: '请选择关联组织标签', trigger: 'change' }]" prop="orgTag">
            <chosenListPanel textAs="tagName" :list.sync="form.orgTag" @select="selectTagShow = true" />
          </el-form-item>
        </col2-block>

        <col2-block title="服务信息">
          <el-form-item label="是否开启服务时间">
            <v-select v-model="form.isOpenTime" :options="isOpenTimeOps" :width="width" />
          </el-form-item>
          <div v-if="timeVisible">
            <el-form-item label="服务时间" :rules="timeRules" prop="time">
              <v-timepicker :isRange="true" :startTime.sync="form.time.startTime" :endTime.sync="form.time.endTime"></v-timepicker>
            </el-form-item>
            <el-form-item label="间隔时间" :rules="[{ required: true, message: '请输入间隔时间', trigger: 'blur' }]" prop="duration">
              <v-input-number v-model="form.duration" placeholder="请输入间隔时间" controls controlsPosition="right" :min="1" :width="width" />
              <label class="tips">分钟 注：间隔时间是指提供一次完整服务需要的平均时间</label>
            </el-form-item>
            <el-form-item label="准备时间" :rules="[{ required: true, message: '请输入准备时间', trigger: 'blur' }]" prop="prepareTime">
              <v-input-number v-model="form.prepareTime" placeholder="请输入准备时间" controls controlsPosition="right" :width="width" />
              <label class="tips">分钟 注：准备时间是指提供一次服务需要提前准备的时间</label>
            </el-form-item>
          </div>
          <el-form-item label="起送价格" :rules="[{ required: true, message: '请输入起送价格', trigger: 'blur' }]" prop="sendPrice">
            <v-input-number v-model="form.sendPrice" placeholder="请输入起送价格" :precision="2" controls controlsPosition="right" :width="width" />
            <label class="tips">注：达到起送价格才可以下单</label>
          </el-form-item>
          <el-form-item label="是否支持售后">
            <v-select v-model="form.isSupportAftersales" :options="isSupportAftersalesOps" :width="width" />
            <label class="tips">注：支持售后的项目服务，业主即可发起线上售后申请</label>
          </el-form-item>
        </col2-block>

        <col2-block title="关联商家信息">
          <el-form-item label="指定商家" :rules="[{ required: true, message: '请选择指定商家', trigger: 'change' }]" prop="shop">
            <v-select2 style="display: inline-block" v-model="form.shop" placeholder="输入商家名称" v-bind="shopParams" :width="width" />
            <label class="tips">注：指定商家是指在指定的项目里面，此项服务由此商家提供服务</label>
          </el-form-item>
          <el-form-item label="指定门店" :rules="[{ required: true, message: '请选择指定门店', trigger: 'change' }]" prop="store">
            <v-select2 style="display: inline-block" v-model="form.store" placeholder="输入门店名称" v-bind="storeParams" :subjoin="storeSubjoin" :width="width" />
            <label class="tips">注：指定门店是指在连锁商家时，指定具体的服务门店</label>
          </el-form-item>
        </col2-block>

        <col2-block v-if="mcheckedVisible" title="特殊信息">
          <el-form-item label="维修分类" :rules="categoryRules" prop="mchecked">
            <div class="category" v-for="(item, index) in form.mchecked" :key="index">
              <div class="title" v-text="getTitle(item.pid)"></div>
              <div class="item" v-for="(categoryItem, categorysIndex) in item.categorys" :key="categorysIndex">
                <v-checkbox v-model="categoryItem.isChecked"></v-checkbox>
                <img :src="categoryItem.categoryIcon" />
                <v-button v-if="item.pid === 'jjwx_weixiu' && categoryItem.isChecked" type="text" :text="categoryItem.categoryName" @click="categoryNameClick(categoryItem)" />
                <div v-else v-text="categoryItem.categoryName"></div>
              </div>
            </div>
          </el-form-item>
        </col2-block>

      </col2-detail>
    </form-panel>

    <multi-select
      title="标签列表"
      :isShow.sync="selectTagShow"
      :searchUrl="getOrgTagSearchListURL"
      :headers="orgTagTableHeader"
      :searchParams="orgTagSearchParams"
      :extraParams="orgTagExtraParams"
      :responseParams="orgTagResponseParams"
      :responseKey="responseKey"
      :backFill.sync="form.orgTag"
    >
      <template #searchSlot>
        <v-input
          label="标签名称"
          v-model="orgTagSearchParams.tagName"
        ></v-input>
      </template>
    </multi-select>

    <el-dialog custom-class="setting-charge-standard-dialog" :title="dialogParams.title + ' -- 设置收费标准'" @closed="closed" width="760px" :visible.sync="settingChargeStandardVisible">
      <div class="setting-charge-standard-dialog-list">
        <el-form ref="settingChargeStandardDialogForm" :model="dialogParams" inline>
          <div class="setting-charge-standard-dialog-item" v-for="(item, index) in dialogParams.list" :key="index">
            <el-form-item :rules="[{ required: true, message: '请输入维修项目', trigger: 'change' }]" :prop="'list.' + index + '.name'">
              <v-input v-model="item.name" placeholder="维修项目" :width="200" />
            </el-form-item>
            <el-form-item :rules="[{ required: true, message: '请输入价格', trigger: 'blur' }]" :prop="'list.' + index + '.price'">
              <v-input-number v-model="item.price" placeholder="价格" :precision="2" controls controls-position="right" :width="140" />
            </el-form-item>
            <el-form-item>
              <v-input v-model="item.remark" placeholder="备注" :width="300" />
            </el-form-item>
            <v-button class="setting-charge-standard-dialog-item-delete" type="" text="-" @click="dialogParamsDelete(index)" />
            </div>
          </el-form>
        <v-button type="" text="+" :width="714" @click="dialogParamsCreate" />
      </div>
      <span class="setting-charge-standard-dialog-footer" slot="footer">
        <v-button text="保存" type="primary" @click="dialogParamsSave" />
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { shopParams, storeParams } from 'common/select2Params'
import { Col2Detail, Col2Block, CheckboxPlus, ChosenListPanel, MultiSelect } from 'components/bussiness'
import { updateServiceInfoURL, queryServiceInfoURL, getSuperiorTagListURL, getOrgTagSearchListURL, updateFeesURL, getFeesURL, querySubTypeURL, getAllFirstLabel, getTagIdsByFirstIds } from './api'
import { setStatusOps, setIsOpenTimeOps, setIsSupportAftersalesOps } from '../map'
import { vCheckbox } from 'components/control'
import { mobileRegular, telephoneRegular } from 'common/regular'

export default {
  name: 'ServiceInfoForm',
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus,
    ChosenListPanel,
    MultiSelect,
    vCheckbox
  },
  data () {
    return {
      width: 182,
      settingChargeStandardVisible: false,
      dialogParams: {
        title: '',
        formId: '',
        list: []
      },
      subId: '',
      communityId: undefined,
      communityName: '',
      childTypeVisible: false,
      childTypeOps: [],
      statusOps: setStatusOps(),
      secondLabels: [],
      conditionsOfUseOps: [{
        label: '游客',
        value: '1'
      }, {
        label: '登录未关联',
        value: '2'
      }, {
        label: '关联组织',
        value: '3'
      }, {
        label: '组织管理员',
        value: '4'
      }, {
        label: '组织超管',
        value: '5'
      }],
      realNameOps: [
        {
          text: '是',
          value: '1'
        },
        {
          text: '否',
          value: '0'
        }
      ],
      selectTagShow: false,
      getOrgTagSearchListURL,
      getSuperiorTagListURL,
      orgTagTableHeader: [
        {
          prop: 'tagName',
          label: '标签名称'
        },
        {
          prop: 'tagLevel',
          label: '标签层级'
        },
        {
          prop: 'parentName',
          label: '上级标签'
        },
        {
          prop: 'statusName',
          label: '状态'
        }
      ],
      orgTagSearchParams: {
        tagName: ''
      },
      orgTagResponseParams: {
        id: 'id',
        name: 'tagName'
      },
      responseKey: {
        id: 'id',
        name: 'tagName'
      },
      isOpenTimeOps: setIsOpenTimeOps(),
      isSupportAftersalesOps: setIsSupportAftersalesOps(),
      shopParams,
      storeParams,
      mcheckedVisible: false,
      categoryRules: [
        {
          validator: (rule, value, callback) => {
            if (value && value.length) {
              let isChecked = 0
              value.forEach(item => {
                const categorys = item.categorys
                if (categorys && categorys.length) {
                  categorys.forEach(category => {
                    if (category.isChecked) {
                      isChecked++
                    }
                  })
                }
              })
              if (isChecked < 1) {
                callback(new Error(`请选择维修分类`))
              } else {
                callback()
              }
            } else {
              callback()
            }
          }
        },
        {
          required: true
        }
      ],
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { startTime, endTime } = value
              if (!startTime) {
                callback(new Error(`请选择服务开始时间`))
              } else if (!endTime) {
                callback(new Error(`请选择服务结束时间`))
              } else {
                callback()
              }
            } else {
              callback()
            }
          }
        },
        {
          required: true
        }
      ],
      form: {
        id: '',
        sort: undefined,
        tag: undefined,
        name: '',
        childType: [],
        phone: '',
        description: '', // 必传字段，应用描述不能在此页面修改
        status: undefined,
        conditionsOfUse: [],
        realName: undefined,
        orgTag: [],
        isOpenTime: undefined,
        time: {
          startTime: '',
          endTime: ''
        },
        duration: 0,
        prepareTime: 0,
        sendPrice: undefined,
        isSupportAftersales: undefined,
        shop: undefined,
        store: undefined,
        mchecked: []
      },
      submitConfig: {
        queryUrl: queryServiceInfoURL,
        queryMethod: 'POST',
        submitUrl: updateServiceInfoURL,
        submitMethod: 'POST',
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置应用服务信息')
    this.$axios.get(`${getAllFirstLabel}?isPartyTag=${1}`).then(res => {
      if (res.status === 100) {
        this.getSecondLabel(res.data.map(item => item.id).join(','))
      }
    })
  },
  computed: {
    timeVisible () {
      return this.form.isOpenTime === 0
    },
    orgTagVisible () {
      return this.form.conditionsOfUse.indexOf('5') !== -1
    },
    storeSubjoin () {
      return {
        shopId: this.form.shop,
        noExamine: 1
      }
    },
    orgTagExtraParams () {
      return {
        communityId: this.communityId
      }
    }
  },
  mounted () {
    const { subId, communityName } = this.$route.query
    this.$refs.formPanel.getData({
      id: subId
    })
    this.subId = subId
    this.communityName = communityName
    this.form.status = this.statusOps[0].value
    this.form.isOpenTime = this.isOpenTimeOps[0].value
    this.form.isSupportAftersales = this.isSupportAftersalesOps[0].value
  },
  methods: {
    conditionsOfUseChange (index, pureCheckedOptions) {
      if (pureCheckedOptions.includes('1') || pureCheckedOptions.includes('2')) {
        if (this.form.realName === '1') {
          this.form.realName = '0'
        }
      }
    },
    realNameChange (val) {
      if (val === '1') {
        const conditionsOfUse = this.form.conditionsOfUse
        if (conditionsOfUse && conditionsOfUse.length) {
          const __conditionsOfUse = []
          conditionsOfUse.forEach(item => {
            if (item !== '1' && item !== '2') {
              __conditionsOfUse.push(item)
            }
          })
          this.form.conditionsOfUse = __conditionsOfUse
        }
      }
    },
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      if (!regExp.test(value) && !connectionRegExp.test(value)) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    getTitle (id) {
      let title = ''
      if (id === 'ggwx_weixiu') {
        title = '公共维修'
      } else if (id === 'jjwx_weixiu') {
        title = '室内维修（点击名称可设置收费标准）'
      }
      return title
    },
    getSuperOrgTagList () {
      let _this = this
      let superParams = { tagName: '企业' }
      let parentId
      this.$axios.get(getSuperiorTagListURL, { params: superParams }).then(res => {
        if (res.status === 100) {
          if (res.data.length) {
            parentId = res.data[0].id
            _this.setOrgTagId(parentId)
          }
        }
      })
    },
    setOrgTagId (parentId) {
      let orgSearchParams = {
        parentId: parentId,
        communityId: this.communityId,
        status: 1,
        executeSearch: 1,
        curPage: 1,
        pageSize: 20
      }
      let _this = this
      this.$axios.get(getOrgTagSearchListURL, { params: orgSearchParams }).then(res => {
        if (res.status === 100) {
          let list = res.data.resultList
          if (Array.isArray(list) && list.length) {
            _this.form.orgTag = list
          }
        }
      })
    },
    async update (d) {
      this.communityId = d['community']
      // 应急分类
      if (d['name'] === '灾害报事') {
        if (d['childType']) {
          let childType = d['childType'].split(',')
          d['childType'] = childType
        }
        const { status, data } = await this.$axios.get(querySubTypeURL)
        if (status === '100') {
          if (data && data.length) {
            const childTypeOps = []
            data.forEach(item => {
              childTypeOps.push({
                label: item.name,
                value: item.value
              })
            })
            this.childTypeOps = childTypeOps
          }
          this.childTypeVisible = true
        }
      }
      // 维修分类
      const maintainCategorys = d['maintainCategorys']
      if (maintainCategorys && maintainCategorys.length) {
        maintainCategorys.forEach(item => {
          const categorys = item.categorys
          if (categorys && categorys.length) {
            categorys.forEach(category => {
              if (category.isChecked === '1') {
                category.isChecked = true
              }
            })
          }
        })
        this.form.mchecked = maintainCategorys
        this.mcheckedVisible = true
      }
      // 可用应用条件
      let conditionsOfUse = d['conditionsOfUse']
      if (conditionsOfUse && conditionsOfUse.length) {
        d['conditionsOfUse'] = conditionsOfUse.split(',')
        // 是否需要实名
        let index = d['conditionsOfUse'].indexOf('6')
        if (index !== -1) {
          d['conditionsOfUse'].splice(index, 1)
          d['realName'] = '1'
        } else {
          d['realName'] = '0'
        }
      }
      // 组织标签
      d['orgTag'] = d['orgLabel'] || []
      // 判断有没有标签，没有的话默认选择企业全部
      // if (!d['orgTag'].length) {
      //   this.getSuperOrgTagList()
      // }
      // 服务时间
      d['time'] = {
        startTime: d.startTime,
        endTime: d.endTime
      }
      Object.keys(this.form).forEach(key => {
        const value = d[key]
        if (![undefined, null].includes(value)) {
          this.form[key] = value
        }
      })
    },
    // 查询所有二级标签
    getSecondLabel (firstTagIds) {
      this.$axios.get(`${getTagIdsByFirstIds}?tagIds=${firstTagIds}`).then(res => {
        if (res.status === 100) {
          this.secondLabels = res.data
        }
      })
    },
    submitBefore (data) {
      if (data['name'] === '灾害报事') {
        let childTypeStr = ''
        const childType = data['childType']
        if (childType && childType.length) {
          childType.forEach(item => {
            if (item) {
              childTypeStr += item + ','
            }
          })
        }
        data['childType'] = childTypeStr
      }
      // 可用应用条件
      if (data['realName'] === '1') {
        data['conditionsOfUse'].push('6')
      }
      delete data['realName']
      data['conditionsOfUse'] = data['conditionsOfUse'].join(',')
      // 组织标签
      const orgTag = []
      // data['orgTag'].forEach(item => {
      //   orgTag.push(item.id)
      // })
      data['orgTag'] = JSON.stringify(this.secondLabels)
      if (this.mcheckedVisible) {
        const mchecked = this.form.mchecked
        if (mchecked && mchecked.length) {
          const data_mchecked = []
          mchecked.forEach(item => {
            const categorys = item.categorys
            if (categorys && categorys.length) {
              categorys.forEach(category => {
                if (category.isChecked) {
                  data_mchecked.push(category)
                }
              })
            }
          })
          data['mchecked'] = JSON.stringify(data_mchecked)
        }
      }
      // 服务时间
      data['startTime'] = data.time.startTime
      data['endTime'] = data.time.endTime

      let dataObject = `${data.name}-${this.communityName}`
      data.dataObject = dataObject

      return true
    },
    async categoryNameClick (categoryItem) {
      const { categoryName, id } = categoryItem
      this.dialogParams.title = categoryName
      this.dialogParams.formId = id
      const { status, data } = await this.$axios.post(getFeesURL, {
        subId: this.subId,
        formId: id
      })
      if (status === '100') {
        this.dialogParams.list = data || []
        this.settingChargeStandardVisible = true
      }
    },
    closed () {
      this.dialogParams = {
        title: '',
        formId: '',
        list: []
      }
    },
    dialogParamsCreate () {
      if (this.dialogParams.list.length > 9) {
        this.$message.info('收费项目已超过10个，建议修改现有收费项目')
        return
      }
      this.dialogParams.list.push({
        name: '',
        price: undefined,
        remark: '',
        status: '0'
      })
    },
    dialogParamsDelete (index) {
      this.dialogParams.list.splice(index, 1)
      this.$nextTick(() => {
        this.$refs['settingChargeStandardDialogForm'].clearValidate()
      }, 20)
    },
    async dialogParamsSave () {
      let result = false
      this.$refs['settingChargeStandardDialogForm'].validate((valid) => {
        if (valid) {
          result = true
        }
      })
      if (result) {
        const mainfees = {
          subId: this.subId,
          formId: this.dialogParams.formId,
          list: this.dialogParams.list
        }
        const params = {
          mainfees: JSON.stringify(mainfees)
        }
        const { status, data } = await this.$axios.post(updateFeesURL, params)
        if (status === '100') {
          this.$message.success('保存成功')
          this.settingChargeStandardVisible = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ServiceInfoForm-wrapper {
  .tips {
    margin-left: 20px;
  }
  .category {
    width: 500px;
    .title {
      background-color: #d0d0d0;
      padding-left: 10px;
      margin-bottom: 10px;
      text-align: left;
    }
    .item {
      display: inline-flex;
      align-items: center;
      width: 150px;
      height: 42px;
      img {
        width: 36px;
        height: 36px;
        margin-right: 6px;
      }
    }
  }
  .setting-charge-standard-dialog {
    .setting-charge-standard-dialog-list {
      text-align: left;
      .setting-charge-standard-dialog-item {
        display: flex;
        .el-form-item {
          margin-right: 0;
        }
        .setting-charge-standard-dialog-item-delete {
          height: 40px;
        }
      }
    }
    .setting-charge-standard-dialog-footer {
      text-align: right;
      border-top: 1px solid #e5e5e5;
    }
  }
}
</style>
