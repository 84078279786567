var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ServiceInfoForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c("el-form-item", { attrs: { label: "所属项目" } }, [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.communityName) },
                    }),
                  ]),
                  _c("el-form-item", { attrs: { label: "应用名称" } }, [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.form.name) },
                    }),
                  ]),
                  _vm.childTypeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应急分类",
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "请选择应急分类",
                                trigger: "blur",
                              },
                            ],
                            prop: "childType",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "default",
                              mode: "checkbox-plus",
                              options: _vm.childTypeOps,
                              value: _vm.form.childType,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "childType", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "服务电话",
                        rules: [
                          {
                            required: true,
                            message: "请输入电话号码",
                            trigger: "change",
                          },
                          { validator: _vm.validatePhone, trigger: "blur" },
                        ],
                        prop: "phone",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入电话号码",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择应用状态",
                            trigger: "change",
                          },
                        ],
                        prop: "status",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.statusOps, width: _vm.width },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "权限信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "可用应用条件",
                        rules: [
                          {
                            required: true,
                            message: "请选择可用应用条件",
                            trigger: "change",
                          },
                        ],
                        prop: "conditionsOfUse",
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          mode: "cascade",
                          options: _vm.conditionsOfUseOps,
                          value: _vm.form.conditionsOfUse,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "conditionsOfUse", $event)
                          },
                          onChange: _vm.conditionsOfUseChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否需要实名",
                        rules: [
                          {
                            required: true,
                            message: "请选择是否需要实名",
                            trigger: "change",
                          },
                        ],
                        prop: "realName",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.realNameOps, width: _vm.width },
                        on: { change: _vm.realNameChange },
                        model: {
                          value: _vm.form.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "realName", $$v)
                          },
                          expression: "form.realName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.orgTagVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联组织标签",
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "请选择关联组织标签",
                                trigger: "change",
                              },
                            ],
                            prop: "orgTag",
                          },
                        },
                        [
                          _c("chosenListPanel", {
                            attrs: { textAs: "tagName", list: _vm.form.orgTag },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(_vm.form, "orgTag", $event)
                              },
                              select: function ($event) {
                                _vm.selectTagShow = true
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "服务信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否开启服务时间" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.isOpenTimeOps, width: _vm.width },
                        model: {
                          value: _vm.form.isOpenTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isOpenTime", $$v)
                          },
                          expression: "form.isOpenTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.timeVisible
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务时间",
                                rules: _vm.timeRules,
                                prop: "time",
                              },
                            },
                            [
                              _c("v-timepicker", {
                                attrs: {
                                  isRange: true,
                                  startTime: _vm.form.time.startTime,
                                  endTime: _vm.form.time.endTime,
                                },
                                on: {
                                  "update:startTime": function ($event) {
                                    return _vm.$set(
                                      _vm.form.time,
                                      "startTime",
                                      $event
                                    )
                                  },
                                  "update:start-time": function ($event) {
                                    return _vm.$set(
                                      _vm.form.time,
                                      "startTime",
                                      $event
                                    )
                                  },
                                  "update:endTime": function ($event) {
                                    return _vm.$set(
                                      _vm.form.time,
                                      "endTime",
                                      $event
                                    )
                                  },
                                  "update:end-time": function ($event) {
                                    return _vm.$set(
                                      _vm.form.time,
                                      "endTime",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "间隔时间",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入间隔时间",
                                    trigger: "blur",
                                  },
                                ],
                                prop: "duration",
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: {
                                  placeholder: "请输入间隔时间",
                                  controls: "",
                                  controlsPosition: "right",
                                  min: 1,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.duration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "duration", $$v)
                                  },
                                  expression: "form.duration",
                                },
                              }),
                              _c("label", { staticClass: "tips" }, [
                                _vm._v(
                                  "分钟 注：间隔时间是指提供一次完整服务需要的平均时间"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "准备时间",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入准备时间",
                                    trigger: "blur",
                                  },
                                ],
                                prop: "prepareTime",
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: {
                                  placeholder: "请输入准备时间",
                                  controls: "",
                                  controlsPosition: "right",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.prepareTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "prepareTime", $$v)
                                  },
                                  expression: "form.prepareTime",
                                },
                              }),
                              _c("label", { staticClass: "tips" }, [
                                _vm._v(
                                  "分钟 注：准备时间是指提供一次服务需要提前准备的时间"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "起送价格",
                        rules: [
                          {
                            required: true,
                            message: "请输入起送价格",
                            trigger: "blur",
                          },
                        ],
                        prop: "sendPrice",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入起送价格",
                          precision: 2,
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sendPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sendPrice", $$v)
                          },
                          expression: "form.sendPrice",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("注：达到起送价格才可以下单"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否支持售后" } },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.isSupportAftersalesOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.isSupportAftersales,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isSupportAftersales", $$v)
                          },
                          expression: "form.isSupportAftersales",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v(
                          "注：支持售后的项目服务，业主即可发起线上售后申请"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "关联商家信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指定商家",
                        rules: [
                          {
                            required: true,
                            message: "请选择指定商家",
                            trigger: "change",
                          },
                        ],
                        prop: "shop",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              placeholder: "输入商家名称",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.shop,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shop", $$v)
                              },
                              expression: "form.shop",
                            },
                          },
                          "v-select2",
                          _vm.shopParams,
                          false
                        )
                      ),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v(
                          "注：指定商家是指在指定的项目里面，此项服务由此商家提供服务"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指定门店",
                        rules: [
                          {
                            required: true,
                            message: "请选择指定门店",
                            trigger: "change",
                          },
                        ],
                        prop: "store",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              placeholder: "输入门店名称",
                              subjoin: _vm.storeSubjoin,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.store,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "store", $$v)
                              },
                              expression: "form.store",
                            },
                          },
                          "v-select2",
                          _vm.storeParams,
                          false
                        )
                      ),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v(
                          "注：指定门店是指在连锁商家时，指定具体的服务门店"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.mcheckedVisible
                ? _c(
                    "col2-block",
                    { attrs: { title: "特殊信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "维修分类",
                            rules: _vm.categoryRules,
                            prop: "mchecked",
                          },
                        },
                        _vm._l(_vm.form.mchecked, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "category" },
                            [
                              _c("div", {
                                staticClass: "title",
                                domProps: {
                                  textContent: _vm._s(_vm.getTitle(item.pid)),
                                },
                              }),
                              _vm._l(
                                item.categorys,
                                function (categoryItem, categorysIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: categorysIndex,
                                      staticClass: "item",
                                    },
                                    [
                                      _c("v-checkbox", {
                                        model: {
                                          value: categoryItem.isChecked,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              categoryItem,
                                              "isChecked",
                                              $$v
                                            )
                                          },
                                          expression: "categoryItem.isChecked",
                                        },
                                      }),
                                      _c("img", {
                                        attrs: {
                                          src: categoryItem.categoryIcon,
                                        },
                                      }),
                                      item.pid === "jjwx_weixiu" &&
                                      categoryItem.isChecked
                                        ? _c("v-button", {
                                            attrs: {
                                              type: "text",
                                              text: categoryItem.categoryName,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.categoryNameClick(
                                                  categoryItem
                                                )
                                              },
                                            },
                                          })
                                        : _c("div", {
                                            domProps: {
                                              textContent: _vm._s(
                                                categoryItem.categoryName
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "标签列表",
          isShow: _vm.selectTagShow,
          searchUrl: _vm.getOrgTagSearchListURL,
          headers: _vm.orgTagTableHeader,
          searchParams: _vm.orgTagSearchParams,
          extraParams: _vm.orgTagExtraParams,
          responseParams: _vm.orgTagResponseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.orgTag,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.selectTagShow = $event
          },
          "update:is-show": function ($event) {
            _vm.selectTagShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "orgTag", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "orgTag", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "标签名称" },
                  model: {
                    value: _vm.orgTagSearchParams.tagName,
                    callback: function ($$v) {
                      _vm.$set(_vm.orgTagSearchParams, "tagName", $$v)
                    },
                    expression: "orgTagSearchParams.tagName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "setting-charge-standard-dialog",
            title: _vm.dialogParams.title + " -- 设置收费标准",
            width: "760px",
            visible: _vm.settingChargeStandardVisible,
          },
          on: {
            closed: _vm.closed,
            "update:visible": function ($event) {
              _vm.settingChargeStandardVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "setting-charge-standard-dialog-list" },
            [
              _c(
                "el-form",
                {
                  ref: "settingChargeStandardDialogForm",
                  attrs: { model: _vm.dialogParams, inline: "" },
                },
                _vm._l(_vm.dialogParams.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "setting-charge-standard-dialog-item",
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请输入维修项目",
                                trigger: "change",
                              },
                            ],
                            prop: "list." + index + ".name",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { placeholder: "维修项目", width: 200 },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请输入价格",
                                trigger: "blur",
                              },
                            ],
                            prop: "list." + index + ".price",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              placeholder: "价格",
                              precision: 2,
                              controls: "",
                              "controls-position": "right",
                              width: 140,
                            },
                            model: {
                              value: item.price,
                              callback: function ($$v) {
                                _vm.$set(item, "price", $$v)
                              },
                              expression: "item.price",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("v-input", {
                            attrs: { placeholder: "备注", width: 300 },
                            model: {
                              value: item.remark,
                              callback: function ($$v) {
                                _vm.$set(item, "remark", $$v)
                              },
                              expression: "item.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-button", {
                        staticClass:
                          "setting-charge-standard-dialog-item-delete",
                        attrs: { type: "", text: "-" },
                        on: {
                          click: function ($event) {
                            return _vm.dialogParamsDelete(index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c("v-button", {
                attrs: { type: "", text: "+", width: 714 },
                on: { click: _vm.dialogParamsCreate },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "setting-charge-standard-dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { text: "保存", type: "primary" },
                on: { click: _vm.dialogParamsSave },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }